<template>
    <Layout>
        <PageHeader :title="title" :items="items"/>
        <div class="row">
            <div class="col-lg-7 px-0">
                <div class="card">
                    <div class="card-body">
                        <h4 class="header-title font-weight-bold"> List of branches</h4>
                        <div>
                            <lottie-loader :loading="showLoader" />
                        </div>
                        <p class="text-muted font-13 mb-4"></p>
                        <div class="row mb-md-2">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select
                                                v-model="perPage"
                                                size="sm"
                                                :options="pageOptions"
                                        ></b-form-select
                                        >&nbsp;entries
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div
                                        id="tickets-table_filter"
                                        class="dataTables_filter text-md-right"
                                >
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Search..."
                                                class="form-control form-control-sm ml-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0" id="table">
                            <b-table
                                    table-class="table table-centered w-100"
                                    thead-tr-class="bg-light"
                                    :items="ListOfBranches"
                                    :fields="tableFields"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"
                                    @filtered="onFiltered"
                            >
                                <template #cell(name)="data">
                                    <span class="font-14 font-weight-bold text-dark">   {{ data.item.name}}</span>
                                </template>
                                <template #cell(location)="data">
                                    <span class="text-dark">   {{ data.item.location}}</span>
                                </template>
                                <template #cell(dateCreated)="data">
                                   <span class="font-12 font-weight-bold text-dark">{{ data.item.dateCreated | moment("MMMM Do YYYY") }}</span>
                                </template>
                                <template #cell(actions)="data">
                                    <a class="mr-2 btn btn-danger" style="cursor: pointer"  href="#" @click="DeleteBranch(data.item)" > <i class="mdi mdi-trash-can"></i>Delete</a>
                                    <a class="mr-2 btn btn-info" style="cursor: pointer"  href="#" @click="EditBranch(data.item.id)"> <i class="mdi mdi-pencil-minus"></i>Edit</a>
                                </template>
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div
                                        class="dataTables_paginate paging_simple_numbers float-right"
                                >
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination
                                                v-model="currentPage"
                                                :total-rows="rows"
                                                :per-page="perPage"
                                        ></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 ">
                <div class="card">
                    <div class="card-body">
                        <h4 class="header-title font-weight-bold mb-3"> Create / Update Branch</h4>

                        <form action="#" @submit.prevent="CreateOrUpdateBranch">

                            <div class="form-group mb-3">
                                <label for="name">Branch name</label>
                                <input
                                        class="form-control"
                                        v-model="branch.name"
                                        type="text"
                                        id="name"
                                        name="name"
                                        placeholder="Enter branch name"
                                        v-validate="'required'"
                                        :class="{'is-invalid': errors.has('name') }"

                                />
                                <span v-show="errors.has('name')" class="help text-danger">{{ errors.first('name') }}</span>
                            </div>


                            <div class="form-group mb-3">
                                <label for="name">Location</label>

                                <input
                                        class="form-control"
                                        id="location"
                                        name="location"
                                        type="text"
                                        v-model="branch.location"
                                        placeholder="Search for a place"
                                />
                                    <gmap-map :center="{lat:1.38, lng:103.8}" :zoom="12">
                                        <gmap-marker :position="{lat:1.38, lng:103.8}">
                                        </gmap-marker>
                                        <gmap-info-window :position="{lat:1.38, lng:103.8}">
                                            Hello world!
                                        </gmap-info-window>
                                    </gmap-map>
                            </div>
                            <div class="form-group mb-0 text-center">
                                <button class="btn btn-primary float-right" :class="branch.id ? 'btn-warning' : 'btn-primary'" type="submit">
                                    {{AddOrUpdateBranch}}
                                </button>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>

    </Layout>
</template>

<script>
    import PageHeader from "@/components/Page-header";
    import LottieLoader from "../../../../components/LottieLoader";
    import Layout from "../../../layouts/main";
    import BaseUrl from "../../../../components/constants";
    import ToastConfigAlert from "../../../../../ToastConfig";
    import SweetAlertConfig from "../../../../../SwalConfig";
    import SweetAlertWrapper from "../../../../../sweetAlert";
    export default {
        name: "ManageBranches",
        components : {
           PageHeader,
           LottieLoader,
            Layout,
            //'GmapAutocomplete': gmapApi().components.Autocomplete
        },
        data() {
            return {
                selectedPlace: null,
                showLoader : false,
                AddOrUpdateBranch : 'Add branch',
                ListOfBranches : [],
                ListOfBranchesRaw : [],
                branch : {
                    id:'',
                  name:'',
                  location:'',
                    lat:'',
                    log:''
                },
                title: "Manage Branches",
                items: [
                    {
                        text: "",
                    },
                    {
                        text: "Dashboards",
                    },
                    {
                        text: "branches",
                        active: true,
                    },
                ],
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortDesc: true,
                sortBy: "id",
                tableFields: [
                    {
                        thClass: 'd-none', tdClass: 'd-none',
                        key: "id",
                        sortable: true,
                    },
                    {
                        label:"Branch name",
                        key: "name",
                        sortable: true,
                    },
                    {
                        label :"Branch location",
                        key: "location",
                        sortable: true,
                    },
                    {
                        label:"Date createed",
                        key: "dateCreated",
                        sortable: true,
                    },
                    {
                        key: "actions",
                        sortable: true,
                    },
                ],
            }
        },
        methods : {
            CreateOrUpdateBranch() {

                this.$validator.validateAll().then((result) => {
                    if (result) {

                        this.sweetAlert.showDialogYesOrNo(Object.assign(SweetAlertConfig, {
                            title : `${this.branch.id ? 'Update Branch ?' : 'Add Branch ?'}`,
                            confirmButtonText :`Yes ${this.branch.id  ? 'Update' : 'Add'}`
                        }), () => {

                            this.showLoader = true
                            if(this.branch.id === '' || this.branch.id === null) {
                                this.AddBranch()
                            }
                            else {
                                this.UpdateBranch()
                            }
                            this.AddOrUpdateBranch = "Add Branch"

                        })

                    }
                })

            },
            AddBranch() {

                const userData = {
                    name : this.branch.name,
                    location : this.branch.location,
                    longitude : this.branch.log,
                    latitude : this.branch.lat
                }


                BaseUrl.AxiosBearer.post("/store/branch/add-branch", userData).then((response) => {
                    this.showLoader = false
                    if(!response.data.status) {
                        this.$bvToast.toast(response.data.message, ToastConfigAlert)
                    }
                    else {
                        this.getListOfBranches()
                        this.clearAddBranch()
                        this.sweetAlert.showMessage("Branch created!  ",response.data.message)
                    }
                }).catch((error) => {
                    this.showLoader = false
                    this.$bvToast.toast(error, ToastConfigAlert)
                })
            },
            UpdateBranch() {

                const userData = {
                    branchId : this.branch.id,
                    name : this.branch.name,
                    location : this.branch.location,
                    longitude : this.branch.log,
                    latitude : this.branch.lat
                }

                BaseUrl.AxiosBearer.put("/store/branch/edit-branch", userData).then((response) => {
                    this.showLoader = false
                    if(!response.data.status) {
                        this.$bvToast.toast(response.data.message, ToastConfigAlert)
                    }
                    else {
                        this.getListOfBranches()
                        this.clearAddBranch()
                        this.sweetAlert.showMessage("Branch updated!  ",response.data.message)
                    }
                }).catch((error) => {
                    this.showLoader = false
                    this.$bvToast.toast(error, ToastConfigAlert)
                })

            },
            DeleteBranch(data) {
                this.sweetAlert.showDialogYesOrNo(Object.assign(SweetAlertConfig, {
                    title : `Delete ${data.name} ?`,
                    confirmButtonText :'Yes Delete'
                }), () => {


                    this.showLoader = true
                    BaseUrl.AxiosBearer.delete("/store/branch/delete-branch/"+ data.id).then((response) => {
                        this.showLoader = false
                        if(!response.data.status) {
                            this.$bvToast.toast(response.data.message, ToastConfigAlert)
                        }
                        else {
                            this.getListOfSuppliers()
                            this.sweetAlert.showMessage("Branch deleted!  ",response.data.message)
                        }
                    }).catch((error) => {
                        this.showLoader = false
                        this.$bvToast.toast(error, ToastConfigAlert)
                    })
                })
            },
            EditBranch(id) {
                const  selectedBrach = this.ListOfBranchesRaw.find((data) => data.id === id)

                if(selectedBrach) {
                    this.branch.id = selectedBrach?.id;
                    this.branch.name = selectedBrach?.name
                    this.branch.location = selectedBrach?.location
                    this.branch.lat = selectedBrach?.latitude
                    this.branch.log = selectedBrach?.longitude

                    this.AddOrUpdateBranch = 'Update branch'
                }

            },
            getListOfBranches() {
                this.$http.get(`${BaseUrl.URL}/store/branch/get-branches`).then((response) => {

                    if(response.data.status) {
                        this.ListOfBranches = []
                        this.ListOfBranchesRaw = []
                        this.ListOfBranchesRaw = response.data.payload

                        response.data.payload.forEach((data) => {
                            this.ListOfBranches.push({
                                id : data.id,
                                name : data.name,
                                location : data.location,
                                dateCreated : data.createdAt
                            })
                        })
                    }
                    else{
                        this.$bvToast.toast(response.data.message,ToastConfigAlert)
                    }

                }).catch((error) => {
                    console.log(" error : ", error)
                    this.$bvToast.toast(error,ToastConfigAlert)
                })
            },
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering

                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
            clearAddBranch() {
                this.branch = {
                    location: '',
                    name: '',
                    lat: '',
                    log: ''
                }
                this.$validator.reset();
            },
        },
        created() {
           this.getListOfBranches()
            this.sweetAlert = new SweetAlertWrapper()
        },
        computed: {
            rows() {
                return this.ListOfBranches.length;
            },
        },
    }
</script>

<style scoped>

</style>